.modal-nav {
  width: 100vw;
  height: 100vh;
  background: #343a7514;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}

.modal-container {
  width: 100%;
  position: relative;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 350ms ease;
  animation: mobileslide 350ms ease;
}

.color {
  color: #262626;
}

.modal-cover {
}

@keyframes mobileslide {
  from {
    left: -200px;
  }
  to {
    left: 0;
  }
}
